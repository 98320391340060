/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { auth, db } from '../firebase';
import { updateEmail, updatePassword } from 'firebase/auth';
import { collection, query, where, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import logActivity from '../utils/activityLogger';
const UserProfile = () => {
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState(currentUser ? currentUser.email : '');
  const [password, setPassword] = useState('');
  const [bookings, setBookings] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [editingBooking, setEditingBooking] = useState(null);
  const [editedBookingDetails, setEditedBookingDetails] = useState({ name: '', email: '', phone: '', date: null });

  useEffect(() => {
    if (currentUser) {
      fetchBookings();
    }
  }, [currentUser]);

  const fetchBookings = async () => {
    if (!currentUser) return;

    const q = query(collection(db, 'bookings'), where('userId', '==', currentUser.uid));
    const querySnapshot = await getDocs(q);
    const userBookings = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setBookings(userBookings);
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      if (email !== currentUser.email) {
        await updateEmail(auth.currentUser, email);
      }

      if (password) {
        await updatePassword(auth.currentUser, password);
      }

      await logActivity(currentUser.uid, 'update_profile', `User updated profile: ${email}`, email);
      setMessage('Profile updated successfully.');
    } catch (err) {
      setError('Error updating profile. Please try again.');
    }
  };

  const handleEditBooking = (booking) => {
    setEditingBooking(booking);
    setEditedBookingDetails({
      name: booking.userName,
      email: booking.userEmail,
      phone: booking.userPhone,
      date: new Date(booking.date),
    });
  };

  const handleUpdateBooking = async (e) => {
    e.preventDefault();
    if (!editingBooking) return;

    try {
      const bookingDocRef = doc(db, 'bookings', editingBooking.id);
      await updateDoc(bookingDocRef, {
        userName: editedBookingDetails.name,
        userEmail: editedBookingDetails.email,
        userPhone: editedBookingDetails.phone,
        date: editedBookingDetails.date.toISOString(),
      });

      await logActivity(currentUser.uid, 'update_booking', `User updated booking for property: ${editingBooking.propertyTitle}`, email);
      setEditingBooking(null);
      setEditedBookingDetails({ name: '', email: '', phone: '', date: null });
      fetchBookings();
      setMessage('Booking updated successfully.');
    } catch (err) {
      console.error('Error updating booking:', err);
      setError('Error updating booking. Please try again.');
    }
  };

  const handleCancelBooking = async (bookingId) => {
    try {
      await deleteDoc(doc(db, 'bookings', bookingId));
      await logActivity(currentUser.uid, 'cancel_booking', `User cancelled booking with ID: ${bookingId}`, email);
      fetchBookings(); // Refresh bookings after deletion
      setMessage('Booking cancelled successfully.');
    } catch (err) {
      console.error('Error cancelling booking:', err);
      setError('Error cancelling booking. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedBookingDetails({ ...editedBookingDetails, [name]: value });
  };

  const handleDateChange = (date) => {
    setEditedBookingDetails({ ...editedBookingDetails, date });
  };

  return (
    <div className="container mx-auto py-8 px-4 lg:px-20">
      <h2 className="text-3xl font-bold mb-6 text-center text-white">User Profile</h2>
      {message && <div className="bg-green-100 text-green-700 p-2 mb-4 rounded">{message}</div>}
      {error && <div className="bg-red-100 text-red-700 p-2 mb-4 rounded">{error}</div>}
      <form onSubmit={handleUpdateProfile} className="bg-blue-800 p-6 rounded-lg shadow-lg space-y-6">
        <div>
          <label className="block text-white mb-2" htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 border border-gray-600 rounded bg-blue-900 text-white focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-white mb-2" htmlFor="password">New Password</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-3 border border-gray-600 rounded bg-blue-900 text-white focus:outline-none focus:border-blue-500"
            placeholder="Leave blank to keep current password"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-4 rounded transition duration-300"
        >
          Update Profile
        </button>
      </form>
      <h3 className="text-2xl font-bold mt-8 mb-4 text-center text-white">Your Bookings</h3>
      {bookings.length === 0 ? (
        <p className="text-center text-white">You have no bookings.</p>
      ) : (
        <div className="space-y-4">
          {bookings.map((booking) => (
            <div key={booking.id} className="bg-blue-800 p-4 rounded-lg shadow-md text-white">
              <p><strong>Property:</strong> {booking.propertyTitle}</p>
              <p><strong>Date:</strong> {new Date(booking.date).toLocaleDateString()}</p>
              <p><strong>Status:</strong> {booking.status}</p>
              <button
                onClick={() => handleEditBooking(booking)}
                className="mt-2 bg-yellow-600 hover:bg-yellow-700 text-white py-2 px-4 rounded"
              >
                Edit Booking
              </button>
              <button
                onClick={() => handleCancelBooking(booking.id)}
                className="mt-2 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded ml-2"
              >
                Cancel Booking
              </button>
            </div>
          ))}
        </div>
      )}

      {editingBooking && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 px-4">
          <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4">Edit Booking for {editingBooking.propertyTitle}</h3>
            <form onSubmit={handleUpdateBooking}>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={editedBookingDetails.name}
                onChange={handleInputChange}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={editedBookingDetails.email}
                onChange={handleInputChange}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
              />
              <input
                type="tel"
                name="phone"
                placeholder="Your Phone Number"
                value={editedBookingDetails.phone}
                onChange={handleInputChange}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
              />
              <DatePicker
                selected={editedBookingDetails.date}
                onChange={handleDateChange}
                minDate={new Date()}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                placeholderText="Select a date"
              />
              <div className="flex justify-end space-x-4 mt-4">
                <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300">
                  Update
                </button>
                <button onClick={() => setEditingBooking(null)} className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition duration-300">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
