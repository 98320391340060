import React, { useState, useContext, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { db, storage } from '../../firebase';
import { addDoc, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { AuthContext } from '../../context/AuthContext';

const PropertyManagement = () => {
  const { currentUser } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [type, setType] = useState('');
  const [location, setLocation] = useState('');
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [features, setFeatures] = useState(['']);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('Current User:', currentUser);
  }, [currentUser]);

  const onDropImages = (acceptedFiles) => {
    setImages((prevImages) => [...prevImages, ...acceptedFiles]);
  };

  const onDropVideos = (acceptedFiles) => {
    setVideos((prevVideos) => [...prevVideos, ...acceptedFiles]);
  };

  const { getRootProps: getRootPropsImages, getInputProps: getInputPropsImages } = useDropzone({
    onDrop: onDropImages,
    accept: 'image/*'
  });

  const { getRootProps: getRootPropsVideos, getInputProps: getInputPropsVideos } = useDropzone({
    onDrop: onDropVideos,
    accept: 'video/*'
  });

  const handleFeatureChange = (index, value) => {
    const newFeatures = [...features];
    newFeatures[index] = value;
    setFeatures(newFeatures);
  };

  const addFeature = () => {
    setFeatures([...features, '']);
  };

  const removeFeature = (index) => {
    const newFeatures = features.filter((_, i) => i !== index);
    setFeatures(newFeatures);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!title) newErrors.title = 'Title is required';
    if (!description) newErrors.description = 'Description is required';
    if (!price) newErrors.price = 'Price is required';
    if (!type) newErrors.type = 'Type is required';
    if (!location) newErrors.location = 'Location is required';
    if (!currentUser) newErrors.user = 'You must be logged in to add a property';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const formatPrice = (value) => {
    const onlyNums = value.replace(/[^\d]/g, '');
    return onlyNums.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handlePriceChange = (e) => {
    setPrice(formatPrice(e.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);

    try {
      if (!currentUser || !currentUser.uid) {
        throw new Error('User not authenticated');
      }

      const imageUrls = await Promise.all(
        images.map(async (image) => {
          const imageRef = ref(storage, `images/${image.name}`);
          await uploadBytes(imageRef, image);
          return await getDownloadURL(imageRef);
        })
      );

      const videoUrls = await Promise.all(
        videos.map(async (video) => {
          const videoRef = ref(storage, `videos/${video.name}`);
          await uploadBytes(videoRef, video);
          return await getDownloadURL(videoRef);
        })
      );

      const propertyData = {
        userId: currentUser.uid,
        title,
        description,
        price: price.replace(/,/g, ''), // Remove commas before storing
        type,
        location,
        images: imageUrls,
        videos: videoUrls,
        features,
      };

      await addDoc(collection(db, 'properties'), propertyData);

      // Reset the form
      setTitle('');
      setDescription('');
      setPrice('');
      setType('');
      setLocation('');
      setImages([]);
      setVideos([]);
      setFeatures(['']);
      setErrors({});
      setLoading(false);
      alert('Property added successfully!');
    } catch (error) {
      console.error('Error adding property:', error);
      setLoading(false);
      if (error.message === 'User not authenticated') {
        alert('You must be logged in to add a property.');
      } else if (error.code === 'permission-denied') {
        alert('You do not have permission to add a property.');
      }
    }
  };

  return (
    <div className="container mx-auto py-8 px-4 lg:px-20">
      <h2 className="text-3xl font-bold mb-6 text-center text-black">Add New Property</h2>
      {errors.user && <p className="text-red-500 text-center">{errors.user}</p>}
      <form onSubmit={handleSubmit} className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-6">
        <div>
          <label className="block text-gray-300 mb-2" htmlFor="title">Title</label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
            required
          />
          {errors.title && <p className="text-red-500">{errors.title}</p>}
        </div>
        <div>
          <label className="block text-gray-300 mb-2" htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
            rows="4"
            required
          ></textarea>
          {errors.description && <p className="text-red-500">{errors.description}</p>}
        </div>
        <div>
          <label className="block text-gray-300 mb-2" htmlFor="price">Price</label>
          <input
            id="price"
            type="text"
            value={price}
            onChange={handlePriceChange}
            className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
            required
          />
          {errors.price && <p className="text-red-500">{errors.price}</p>}
        </div>
        <div>
          <label className="block text-gray-300 mb-2" htmlFor="type">Type</label>
          <input
            id="type"
            type="text"
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
            required
          />
          {errors.type && <p className="text-red-500">{errors.type}</p>}
        </div>
        <div>
          <label className="block text-gray-300 mb-2" htmlFor="location">Location</label>
          <input
            id="location"
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
            required
          />
          {errors.location && <p className="text-red-500">{errors.location}</p>}
        </div>
        <div>
          <label className="block text-gray-300 mb-2" htmlFor="features">Features</label>
          {features.map((feature, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={feature}
                onChange={(e) => handleFeatureChange(index, e.target.value)}
                className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
              />
              <button
                type="button"
                onClick={() => removeFeature(index)}
                className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addFeature}
            className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
          >
            Add Feature
          </button>
        </div>
        <div>
          <label className="block text-gray-300 mb-2" htmlFor="images">Images</label>
          <div {...getRootPropsImages()} className="border-2 border-dashed border-gray-600 p-6 rounded-lg bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500">
            <input {...getInputPropsImages()} />
            <p>Drag & drop images here, or click to select images</p>
          </div>
          <div className="mt-2 flex flex-wrap">
            {images.map((image, index) => (
              <img key={index} src={URL.createObjectURL(image)} alt="Preview" className="h-20 w-20 object-cover m-1 rounded" />
            ))}
          </div>
        </div>
        <div>
          <label className="block text-gray-300 mb-2" htmlFor="videos">Videos</label>
          <div {...getRootPropsVideos()} className="border-2 border-dashed border-gray-600 p-6 rounded-lg bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500">
            <input {...getInputPropsVideos()} />
            <p>Drag & drop videos here, or click to select videos</p>
          </div>
          <div className="mt-2 flex flex-wrap">
            {videos.map((video, index) => (
              <video key={index} controls className="h-20 w-20 object-cover m-1 rounded">
                <source src={URL.createObjectURL(video)} />
              </video>
            ))}
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded transition duration-300"
          disabled={loading}
        >
          {loading ? 'Adding Property...' : 'Add Property'}
        </button>
      </form>
    </div>
  );
};

export default PropertyManagement;
