/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import path as necessary
import emailjs from '@emailjs/browser'; // Import EmailJS
import { RingLoader } from 'react-spinners'; // Import RingLoader for loading animation

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });
  const [loading, setLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      // Add form data to Firestore
      await addDoc(collection(db, 'messages'), {
        senderName: formData.name,
        senderEmail: formData.email,
        phone: formData.phone,
        subject: formData.subject,
        message: formData.message,
        replied: false,
      });

      // Send email to admin and confirmation to user
      await sendEmails();

      alert('Message sent successfully!');
      setFormData({ name: '', email: '', phone: '', subject: '', message: '' });
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    } finally {
      setLoading(false); // End loading
    }
  };

  const sendEmails = async () => {
    const serviceId = 'service_nomj0fh';
    const adminTemplateId = 'template_uj3ld78'; // Template ID for admin notification
    const userTemplateId = 'template_zfxvfym'; // Template ID for user confirmation

    const adminEmailParams = {
      to_name: 'Admin',
      from_name: formData.name,
      from_email: formData.email,
      phone: formData.phone,
      subject: formData.subject,
      message: formData.message,
    };

    const userEmailParams = {
      to_name: formData.name,
      from_name: 'The Guys Real Estate Team',
      to_email: formData.email, // This ensures the email is sent to the user
      message: 'Thank you for your enquiry. Someone from our team will get back to you soon.',
    };

    try {
      // Send email to the admin
      await emailjs.send(serviceId, adminTemplateId, adminEmailParams, 'zfpxcV0EC9ii0SrOM');

      // Send confirmation email to the user
      await emailjs.send(serviceId, userTemplateId, userEmailParams, 'zfpxcV0EC9ii0SrOM');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <div className="container mx-auto py-16 px-4 lg:px-20">
      <h1 className="text-4xl font-bold mb-10 text-center text-white">Contact Us</h1>

      <section className="mb-12 text-center">
        <p className="text-gray-300 mb-6 max-w-2xl mx-auto">
          We'd love to hear from you! Whether you have questions about our services, need assistance with finding a property, or just want to learn more about us, please reach out using the form below or via our contact details.
        </p>
      </section>

      <section className="mb-12">
        <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-gray-800 p-8 rounded-lg shadow-lg space-y-6">
          <div>
            <label htmlFor="name" className="block text-gray-300">Your Full Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full mt-2 p-3 border border-gray-600 rounded bg-gray-900 text-gray-300"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-300">Your Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full mt-2 p-3 border border-gray-600 rounded bg-gray-900 text-gray-300"
              required
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-gray-300">Your Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full mt-2 p-3 border border-gray-600 rounded bg-gray-900 text-gray-300"
              required
            />
          </div>
          <div>
            <label htmlFor="subject" className="block text-gray-300">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="w-full mt-2 p-3 border border-gray-600 rounded bg-gray-900 text-gray-300"
              required
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-gray-300">Your Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full mt-2 p-3 border border-gray-600 rounded bg-gray-900 text-gray-300"
              rows="5"
              required
            ></textarea>
          </div>
          <button type="submit" className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded flex justify-center items-center">
            {loading ? (
              <RingLoader size={24} color={"#ffffff"} loading={loading} />
            ) : (
              "Send Message"
            )}
          </button>
        </form>
      </section>

      <section className="mb-12 text-center">
        <h2 className="text-3xl font-semibold mb-6 text-white">Contact Information</h2>
        <p className="text-gray-300">Kumasi, Ashanti Region Ghana</p>
        <p className="text-gray-300 mt-4">Email: <a href="mailto:theguysrealestate@gmail.com" className="hover:underline text-blue-500">theguysrealestate@gmail.com</a></p>
        <p className="text-gray-300 mt-2">Phone: <a href="tel:+233508119762" className="hover:underline text-blue-500">+233 508119762</a> and <a href="tel:+233550742307" className="hover:underline text-blue-500">+233 550742307</a></p>
        <p className="text-gray-300 mt-4">Office Hours: Monday - Friday: 9 AM - 6 PM</p>
      </section>

      <section className="mb-12 text-center">
        <h2 className="text-3xl font-semibold mb-6 text-white">Find Us Here</h2>
        <div className="w-full h-80">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1986.206680673047!2d-1.6298999999999998!3d6.6745999999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10275b3d5d44f401%3A0xcdb5a67447c6c6a7!2sKumasi%2C%20Ghana!5e0!3m2!1sen!2sus!4v1629461813004!5m2!1sen!2sus"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
