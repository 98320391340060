/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AuthContext } from '../../context/AuthContext';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';

const FeaturedProperties = () => {
  const { currentUser } = useContext(AuthContext);
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProperties = async () => {
      const querySnapshot = await getDocs(collection(db, 'properties'));
      const propertiesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      // Filter out properties without images and take the first three
      const filteredProperties = propertiesData.filter(property => property.images && property.images.length > 0);
      setProperties(filteredProperties.slice(0, 3));
    };

    fetchProperties();
  }, []);

  const handleViewDetails = (propertyId) => {
    if (currentUser) {
      navigate(`/listings`);
    } else {
      navigate('/login');
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="container mx-auto py-8">
      <h2 className="text-3xl font-bold mb-6 text-center text-white">Featured Properties</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {properties.map(property => (
          <div
            key={property.id}
            className="border rounded-lg overflow-hidden shadow-lg transform hover:scale-105 transition duration-300 bg-gray-800"
          >
            <Slider {...sliderSettings}>
              {property.images && property.images.filter(image => !image.endsWith('.mp4')).map((image, index) => (
                <div key={index} className="w-full h-48">
                  <img src={image} alt={`Property Image ${index}`} className="w-full h-full object-cover" />
                </div>
              ))}
            </Slider>
            <div className="p-4 featured-property-text text-white">
              <h3 className="text-xl font-semibold mb-2">{property.title}</h3>
              <p className="mb-2">{property.description}</p>
              <p className="font-bold">${Number(property.price).toLocaleString()}</p>
              <button
                onClick={() => handleViewDetails(property.id)}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
              >
                View Details
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedProperties;
