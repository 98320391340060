import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Listings from './pages/Listings';
import Login from './pages/Login';
import Signup from './pages/Signup';
import UserProfile from './pages/UserProfile';
import AdminPanel from './pages/AdminPanel';
import Footer from './components/Layout/Footer';
import Navbar from './components/Layout/Navbar';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import AdminLogin from './components/Auth/AdminLogin';
import { AuthProvider, AuthContext } from './context/AuthContext'; 

const AppContent = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <>
      <Navbar />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/listings" element={<Listings />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/admin-login" element={<AdminLogin />} />
        </Routes>
      </main>
      {!(currentUser && currentUser.email === 'sam@realestate.com') && <Footer />}
    </>
  );
};

const App = () => {
  return (
    <AuthProvider> {/* Wrap with AuthProvider */}
      <Router>
        <div className="flex flex-col min-h-screen">
          <AppContent />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
