import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { db, storage } from '../../firebase';
import { collection, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const EditProperty = () => {
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [type, setType] = useState('');
  const [location, setLocation] = useState('');
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [features, setFeatures] = useState(['']);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const propertiesSnapshot = await getDocs(collection(db, 'properties'));
        const propertiesList = propertiesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setProperties(propertiesList);
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    fetchProperties();
  }, []);

  const handleSelectProperty = (property) => {
    setSelectedProperty(property);
    setTitle(property.title);
    setDescription(property.description);
    setPrice(property.price.toString());
    setType(property.type);
    setLocation(property.location);
    setImages(property.images || []);
    setVideos(property.videos || []);
    setFeatures(property.features || ['']);
  };

  const handleFeatureChange = (index, value) => {
    const newFeatures = [...features];
    newFeatures[index] = value;
    setFeatures(newFeatures);
  };

  const addFeature = () => {
    setFeatures([...features, '']);
  };

  const removeFeature = (index) => {
    const newFeatures = features.filter((_, i) => i !== index);
    setFeatures(newFeatures);
  };

  const validateForm = () => {
    return title && description && price && type && location;
  };

  const formatPrice = (value) => {
    const onlyNums = value.replace(/[^\d]/g, '');
    return onlyNums.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handlePriceChange = (e) => {
    setPrice(formatPrice(e.target.value));
  };

  const handleUpdateProperty = async () => {
    if (selectedProperty && validateForm()) {
      setLoading(true);
      try {
        const imageUrls = await Promise.all(
          images.map(async (image) => {
            if (typeof image === 'string') return image;
            const imageRef = ref(storage, `images/${image.name}`);
            await uploadBytes(imageRef, image);
            return await getDownloadURL(imageRef);
          })
        );

        const videoUrls = await Promise.all(
          videos.map(async (video) => {
            if (typeof video === 'string') return video;
            const videoRef = ref(storage, `videos/${video.name}`);
            await uploadBytes(videoRef, video);
            return await getDownloadURL(videoRef);
          })
        );

        const propertyRef = doc(db, 'properties', selectedProperty.id);
        await updateDoc(propertyRef, {
          title,
          description,
          price: price.replace(/,/g, ''), // Remove commas before storing
          type,
          location,
          images: imageUrls,
          videos: videoUrls,
          features,
        });

        // Update the local state
        setProperties((prevProperties) =>
          prevProperties.map((property) =>
            property.id === selectedProperty.id
              ? { ...property, title, description, price: price.replace(/,/g, ''), type, location, images: imageUrls, videos: videoUrls, features }
              : property
          )
        );

        setLoading(false);
        alert('Property updated successfully!');
      } catch (error) {
        console.error('Error updating property:', error);
        setLoading(false);
      }
    } else {
      alert('Please fill out all required fields.');
    }
  };

  const handleDeleteProperty = async (id) => {
    setLoading(true);
    try {
      await deleteDoc(doc(db, 'properties', id));
      setProperties(properties.filter((property) => property.id !== id));
      setSelectedProperty(null);
      setLoading(false);
      alert('Property deleted successfully!');
    } catch (error) {
      console.error('Error deleting property:', error);
      setLoading(false);
    }
  };

  const onDropImages = (acceptedFiles) => {
    setImages((prevImages) => [...prevImages, ...acceptedFiles]);
  };

  const onDropVideos = (acceptedFiles) => {
    setVideos((prevVideos) => [...prevVideos, ...acceptedFiles]);
  };

  const { getRootProps: getRootPropsImages, getInputProps: getInputPropsImages } = useDropzone({
    onDrop: onDropImages,
    accept: 'image/*'
  });

  const { getRootProps: getRootPropsVideos, getInputProps: getInputPropsVideos } = useDropzone({
    onDrop: onDropVideos,
    accept: 'video/*'
  });

  return (
    <div className="container mx-auto py-8 px-4 lg:px-20">
      <h2 className="text-3xl font-bold mb-6 text-center text-black">Edit Property</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold mb-4 text-white">Property List</h3>
          {properties.length === 0 ? (
            <p className="text-gray-300">No properties available.</p>
          ) : (
            <ul className="space-y-4">
              {properties.map((property) => (
                <li
                  key={property.id}
                  className="p-4 border-b border-gray-600 cursor-pointer hover:bg-gray-700"
                  onClick={() => handleSelectProperty(property)}
                >
                  <h4 className="text-xl font-semibold text-white">{property.title}</h4>
                  <p className="text-gray-400">{property.description}</p>
                  <p className="text-gray-400">${Number(property.price).toLocaleString()}</p>
                  <p className="text-gray-400">{property.type}</p>
                  <p className="text-gray-400">{property.location}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
        {selectedProperty && (
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4 text-white">Edit Property Details</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-gray-300 mb-2" htmlFor="title">Title</label>
                <input
                  id="title"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2" htmlFor="description">Description</label>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
                  rows="4"
                ></textarea>
              </div>
              <div>
                <label className="block text-gray-300 mb-2" htmlFor="price">Price</label>
                <input
                  id="price"
                  type="text"
                  value={price}
                  onChange={handlePriceChange}
                  className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2" htmlFor="type">Type</label>
                <input
                  id="type"
                  type="text"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2" htmlFor="location">Location</label>
                <input
                  id="location"
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2" htmlFor="features">Features</label>
                {features.map((feature, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={feature}
                      onChange={(e) => handleFeatureChange(index, e.target.value)}
                      className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
                    />
                    <button
                      type="button"
                      onClick={() => removeFeature(index)}
                      className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addFeature}
                  className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
                >
                  Add Feature
                </button>
              </div>
              <div>
                <label className="block text-gray-300 mb-2" htmlFor="images">Images</label>
                <div {...getRootPropsImages()} className="border-2 border-dashed border-gray-600 p-6 rounded-lg bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500">
                  <input {...getInputPropsImages()} />
                  <p>Drag & drop images here, or click to select images</p>
                </div>
                <div className="mt-2 flex flex-wrap">
                  {images.map((image, index) => (
                    <img key={index} src={typeof image === 'string' ? image : URL.createObjectURL(image)} alt="Preview" className="h-20 w-20 object-cover m-1 rounded" />
                  ))}
                </div>
              </div>
              <div>
                <label className="block text-gray-300 mb-2" htmlFor="videos">Videos</label>
                <div {...getRootPropsVideos()} className="border-2 border-dashed border-gray-600 p-6 rounded-lg bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500">
                  <input {...getInputPropsVideos()} />
                  <p>Drag & drop videos here, or click to select videos</p>
                </div>
                <div className="mt-2 flex flex-wrap">
                  {videos.map((video, index) => (
                    <video key={index} controls className="h-20 w-20 object-cover m-1 rounded">
                      <source src={typeof video === 'string' ? video : URL.createObjectURL(video)} />
                    </video>
                  ))}
                </div>
              </div>
              <div className="flex justify-between">
                <button
                  onClick={handleUpdateProperty}
                  className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                  disabled={loading}
                >
                  {loading ? 'Updating...' : 'Update Property'}
                </button>
                <button
                  onClick={() => handleDeleteProperty(selectedProperty.id)}
                  className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                  disabled={loading}
                >
                  {loading ? 'Deleting...' : 'Delete Property'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProperty;
