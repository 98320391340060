/* eslint-disable jsx-a11y/img-redundant-alt */
// src/pages/Listings.js
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AuthContext } from "../context/AuthContext";
import { db } from "../firebase";
import { collection, getDocs, addDoc } from "firebase/firestore";
import logActivity from "../utils/activityLogger";
import emailjs from "@emailjs/browser";
import { RingLoader } from "react-spinners"; // Import RingLoader
import { FaChevronLeft, FaChevronRight, FaExpand } from "react-icons/fa"; // Import Icons

const Listings = () => {
  const { currentUser } = useContext(AuthContext);
  const [properties, setProperties] = useState([]);
  const [types, setTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [bookingDetails, setBookingDetails] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState({
    type: "",
    location: "",
    minPrice: "",
    maxPrice: "",
  });
  const [loading, setLoading] = useState(false); // Loading state
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Current Image Index
  const [fullSizeImage, setFullSizeImage] = useState(null); // Full-size image state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProperties = async () => {
      const querySnapshot = await getDocs(collection(db, "properties"));
      const propertiesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProperties(propertiesData);

      const propertyTypes = [
        ...new Set(propertiesData.map((property) => property.type)),
      ];
      setTypes(propertyTypes);

      const propertyLocations = [
        ...new Set(propertiesData.map((property) => property.location)),
      ];
      setLocations(propertyLocations);
    };

    fetchProperties();
  }, []);

  const handleBookAppointment = (property) => {
    if (currentUser) {
      setSelectedProperty(property);
    } else {
      navigate("/login");
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    const [hours, minutes] = selectedTime.split(":");
    const selectedDateTime = new Date(selectedDate);
    selectedDateTime.setHours(hours, minutes);

    if (selectedDateTime >= new Date()) {
      setSelectedTime(selectedTime);
    } else {
      alert("Please select a future time.");
    }
  };

  const handleConfirmBooking = async () => {
    if (
      selectedDate &&
      selectedTime &&
      bookingDetails.name &&
      bookingDetails.email &&
      bookingDetails.phone
    ) {
      setLoading(true); // Start loading
      try {
        const bookingData = {
          propertyId: selectedProperty.id,
          propertyTitle: selectedProperty.title,
          userId: currentUser.uid,
          userName: bookingDetails.name,
          userEmail: bookingDetails.email,
          userPhone: bookingDetails.phone,
          date: selectedDate.toISOString(),
          time: selectedTime,
          status: "booked",
        };

        const messageData = {
          senderName: bookingDetails.name,
          senderEmail: bookingDetails.email,
          subject: `Booking Request for ${selectedProperty.title}`,
          message: `A booking request has been made for the property ${selectedProperty.title} on ${selectedDate.toLocaleDateString()} at ${selectedTime} by ${bookingDetails.name}.`,
          snippet: `Booking request for ${selectedProperty.title}`,
          replied: false,
          timestamp: new Date(),
        };

        await addDoc(collection(db, "bookings"), bookingData);
        await addDoc(collection(db, "messages"), messageData);

        // Send emails
        await sendEmail(
          "theguysrealestate@gmail.com",
          bookingDetails.email,
          "owu19003453@gmail.com",
          serviceId,
          adminTemplateId,
          bookieTemplateId,
          developerTemplateId
        );

        await logActivity(
          currentUser.uid,
          "book_appointment",
          `Appointment booked for property: ${selectedProperty.title}`
        );

        alert(
          `Appointment booked for ${selectedProperty.title} on ${selectedDate.toLocaleDateString()} at ${selectedTime} by ${bookingDetails.name}`
        );
        setSelectedProperty(null);
        setSelectedDate(null);
        setSelectedTime("");
        setBookingDetails({ name: "", email: "", phone: "" });
      } catch (err) {
        console.error("Error booking appointment:", err);
        alert("Failed to book appointment. Please try again.");
      } finally {
        setLoading(false); // End loading
      }
    } else {
      alert("Please fill all the fields and select a date and time.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingDetails({ ...bookingDetails, [name]: value });
  };

  const handleMinPriceChange = (e) => {
    const value = e.target.value;
    setFilter({ ...filter, minPrice: value >= 0 ? value : "" });
  };

  const handleMaxPriceChange = (e) => {
    const value = e.target.value;
    setFilter({ ...filter, maxPrice: value >= 0 ? value : "" });
  };

  const filteredProperties = properties.filter(
    (property) =>
      property.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (filter.type ? property.type.toLowerCase() === filter.type.toLowerCase() : true) &&
      (filter.location
        ? property.location.toLowerCase() === filter.location.toLowerCase()
        : true) &&
      (filter.minPrice ? property.price >= parseInt(filter.minPrice) : true) &&
      (filter.maxPrice ? property.price <= parseInt(filter.maxPrice) : true)
  );

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  const serviceId = "service_nomj0fh";
  const adminTemplateId = "template_ctpg23h";
  const bookieTemplateId = "template_q7gud19";
  const developerTemplateId = "template_ayk3eyb";

  const sendEmail = async (
    adminEmail,
    bookieEmail,
    developerEmail,
    serviceId,
    adminTemplateId,
    bookieTemplateId,
    developerTemplateId
  ) => {
    const sendEmailHelper = async (email, templateId, templateParams) => {
      try {
        const response = await emailjs.send(
          serviceId,
          templateId,
          templateParams,
          { publicKey: "zfpxcV0EC9ii0SrOM" }
        );

        console.log("SUCCESS!", response.status, response.text);
      } catch (err) {
        console.error(`Failed... ${err}`);
      }
    };

    const adminTemplateParams = {
      property_name: selectedProperty.title,
      property_price: Number(selectedProperty.price).toLocaleString(),
      booking_date: selectedDate.toLocaleDateString(),
      booking_time: selectedTime,
      user_name: bookingDetails.name,
      user_email: bookingDetails.email,
      user_phone: bookingDetails.phone,
      message: `A booking request has been made for the property ${selectedProperty.title} on ${selectedDate.toLocaleDateString()} at ${selectedTime} by ${bookingDetails.name}.`,
    };

    await sendEmailHelper(adminEmail, adminTemplateId, adminTemplateParams);

    const bookieTemplateParams = {
      to_name: bookingDetails.name,
      to_email: bookingDetails.email,
      property_name: selectedProperty.title,
      property_price: Number(selectedProperty.price).toLocaleString(),
      booking_date: selectedDate.toLocaleDateString(),
      booking_time: selectedTime,
      admin_email: adminEmail,
      message: `You've successfully booked ${selectedProperty.title} posted by ${adminEmail} at a price of ${selectedProperty.price} on ${selectedDate.toLocaleDateString()} at ${selectedTime}.`,
      subject: `Your Booking Confirmation for ${selectedProperty.title}`
    };

    await sendEmailHelper(bookieEmail, bookieTemplateId, bookieTemplateParams);

    const developerTemplateParams = {
      property_name: selectedProperty.title,
      property_price: Number(selectedProperty.price).toLocaleString(),
      booking_date: selectedDate.toLocaleDateString(),
      booking_time: selectedTime,
      user_name: bookingDetails.name,
      user_email: bookingDetails.email,
      user_phone: bookingDetails.phone,
      message: `A booking request has been made for the property ${selectedProperty.title} on ${selectedDate.toLocaleDateString()} at ${selectedTime} by ${bookingDetails.name}.`,
    };

    await sendEmailHelper(developerEmail, developerTemplateId, developerTemplateParams);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedProperty.images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === selectedProperty.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleImageClick = (image) => {
    setFullSizeImage(image);
  };

  const closeFullSizeImage = () => {
    setFullSizeImage(null);
  };

  return (
    <div className="container mx-auto py-8 px-4">
      <h2 className="text-3xl font-bold mb-6 text-center text-white">
        Property Listings
      </h2>

      <div className="mb-4 flex flex-col md:flex-row md:space-x-4">
        <input
          type="text"
          placeholder="Search properties"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="mb-2 md:mb-0 p-2 border border-gray-300 rounded"
        />
        <select
          value={filter.type}
          onChange={(e) => setFilter({ ...filter, type: e.target.value })}
          className="mb-2 md:mb-0 p-2 border border-gray-300 rounded"
        >
          <option value="">All Types</option>
          {types.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
        <select
          value={filter.location}
          onChange={(e) => setFilter({ ...filter, location: e.target.value })}
          className="mb-2 md:mb-0 p-2 border border-gray-300 rounded"
        >
          <option value="">All Locations</option>
          {locations.map((location) => (
            <option key={location} value={location}>
              {location}
            </option>
          ))}
        </select>
        <input
          type="number"
          placeholder="Min Price"
          value={filter.minPrice}
          onChange={handleMinPriceChange}
          className="mb-2 md:mb-0 p-2 border border-gray-300 rounded"
        />
        <input
          type="number"
          placeholder="Max Price"
          value={filter.maxPrice}
          onChange={handleMaxPriceChange}
          className="mb-2 md:mb-0 p-2 border border-gray-300 rounded"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredProperties.map((property) => (
          <div
            key={property.id}
            className="border rounded-lg overflow-hidden shadow-lg transform hover:scale-105 transition duration-300 bg-gray-800"
          >
            <Slider {...sliderSettings}>
              {property.images &&
                property.images.map((image, index) => (
                  <div key={index} className="w-full h-48">
                    <img
                      src={image}
                      alt={`Property Image ${index}`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
              {property.videos &&
                property.videos.map((video, index) => (
                  <div key={index} className="w-full h-48">
                    <video controls className="w-full h-full object-cover">
                      <source src={video} type="video/mp4" />
                    </video>
                  </div>
                ))}
            </Slider>
            <div className="p-4 text-white">
              <h3 className="text-xl font-semibold mb-2">
                {property.title}
              </h3>
              <p className="mb-2">{property.description}</p>
              <p className="font-bold">
                ${Number(property.price).toLocaleString()}
              </p>
              <button
                onClick={() => handleBookAppointment(property)}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
              >
                Book Appointment
              </button>
            </div>
          </div>
        ))}
      </div>

      {selectedProperty && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 px-4 z-50">
          <div className="bg-white p-6 rounded shadow-md w-full max-w-md max-h-screen overflow-y-auto" style={{ height: "680px", width: "500px" }}>
            <h3 className="text-xl font-semibold mb-4">
              Book Appointment for {selectedProperty.title}
            </h3>
            <div className="relative flex items-center justify-center mb-4">
              {selectedProperty.images && selectedProperty.images.length > 0 && (
                <div className="relative">
                  <img
                    src={selectedProperty.images[currentImageIndex]}
                    alt={`Property Image ${currentImageIndex}`}
                    className="w-full h-full object-cover mb-2 cursor-pointer"
                    style={{ maxHeight: "200px", objectFit: "contain" }}
                    onClick={() => handleImageClick(selectedProperty.images[currentImageIndex])}
                  />
                  <button
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
                    onClick={handlePreviousImage}
                  >
                    <FaChevronLeft />
                  </button>
                  <button
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
                    onClick={handleNextImage}
                  >
                    <FaChevronRight />
                  </button>
                  <button
                    className="absolute bottom-0 right-0 bg-black bg-opacity-50 text-white p-2 rounded-full"
                    onClick={() => handleImageClick(selectedProperty.images[currentImageIndex])}
                  >
                    <FaExpand />
                  </button>
                </div>
              )}
            </div>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={bookingDetails.name}
              onChange={handleInputChange}
              className="w-full mb-4 p-2 border border-gray-300 rounded"
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={bookingDetails.email}
              onChange={handleInputChange}
              className="w-full mb-4 p-2 border border-gray-300 rounded"
            />
            <input
              type="tel"
              name="phone"
              placeholder="Your Phone Number"
              value={bookingDetails.phone}
              onChange={handleInputChange}
              className="w-full mb-4 p-2 border border-gray-300 rounded"
            />
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              minDate={new Date()}
              className="w-full mb-4 p-2 border border-gray-300 rounded"
              placeholderText="Select a date"
            />
            <input
              type="time"
              name="time"
              value={selectedTime}
              onChange={handleTimeChange}
              className="w-full mb-4 p-2 border border-gray-300 rounded"
            />
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={handleConfirmBooking}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
              >
                {loading ? (
                  <RingLoader size={24} color={"#ffffff"} loading={loading} />
                ) : (
                  "Confirm"
                )}
              </button>
              <button
                onClick={() => setSelectedProperty(null)}
                className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {fullSizeImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="relative">
            <img
              src={fullSizeImage}
              alt="Full Size"
              className="w-auto h-auto max-w-full max-h-full"
            />
            <button
              className="absolute top-0 right-0 bg-black bg-opacity-50 text-white p-2 rounded-full"
              onClick={closeFullSizeImage}
            >
              <FaExpand />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Listings;
