// src/pages/Home.js
import React from 'react';
import Hero from '../components/Layout/Hero';
import FeaturedProperties from '../components/Layout/featuredProperties';

const HomePage = () => {
  return (
    <>
      <Hero />
      <FeaturedProperties/>

    </>
  );
};

export default HomePage;
