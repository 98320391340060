import React, { useState, useEffect, useContext } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { AuthContext } from '../../context/AuthContext';
import { FaTrash } from 'react-icons/fa'; // Ensure this import is here

const MessageManagement = () => {
  const { currentUser } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reply, setReply] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMessages, setFilteredMessages] = useState([]);

  useEffect(() => {
    if (!currentUser) {
      console.error('User not authenticated');
      return;
    }

    const fetchMessages = async () => {
      try {
        const messagesSnapshot = await getDocs(collection(db, 'messages'));
        const messagesList = messagesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setMessages(messagesList);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [currentUser]);

  useEffect(() => {
    setFilteredMessages(
      messages.filter(
        (message) =>
          message.senderName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          message.subject.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, messages]);

  const handleSelectMessage = (message) => {
    setSelectedMessage(message);
  };

  const handleReplyChange = (e) => {
    setReply(e.target.value);
  };

  const handleReplySubmit = async () => {
    if (selectedMessage) {
      try {
        // Implement the reply logic here (e.g., send an email)
        alert(`Reply sent to: ${selectedMessage.senderEmail}\n\n${reply}`);

        // Mark the message as replied (update the Firestore document)
        const messageRef = doc(db, 'messages', selectedMessage.id);
        await updateDoc(messageRef, { replied: true });

        // Reset the form
        setReply('');
        setSelectedMessage(null);
      } catch (error) {
        console.error('Error sending reply:', error);
      }
    }
  };

  const handleDeleteMessage = async (id) => {
    try {
      await deleteDoc(doc(db, 'messages', id));
      setMessages(messages.filter((message) => message.id !== id));
      setSelectedMessage(null);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  return (
    <div className="container mx-auto py-8 px-4 lg:px-20">
      <h2 className="text-3xl font-bold mb-6 text-center text-black">Message Management</h2>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search messages"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <ul className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
            {filteredMessages.map((message) => (
              <li
                key={message.id}
                className="p-4 border-b border-gray-600 cursor-pointer hover:bg-gray-700 flex justify-between items-center"
                onClick={() => handleSelectMessage(message)}
              >
                <div>
                  <h3 className="text-xl font-semibold text-white">{message.subject}</h3>
                  <p className="text-gray-400">{message.senderName}</p>
                  <p className="text-gray-500">{message.snippet}</p>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteMessage(message.id);
                  }}
                  className="text-red-600 hover:text-red-800"
                >
                  <FaTrash />
                </button>
              </li>
            ))}
          </ul>
        </div>
        {selectedMessage && (
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4 text-white">Message Details</h3>
            <p className="text-gray-300 mb-2"><strong>From:</strong> {selectedMessage.senderName} ({selectedMessage.senderEmail})</p>
            <p className="text-gray-300 mb-4"><strong>Subject:</strong> {selectedMessage.subject}</p>
            <p className="text-gray-300 mb-6">{selectedMessage.message}</p>
            <textarea
              value={reply}
              onChange={handleReplyChange}
              className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500 mb-4"
              rows="4"
              placeholder="Type your reply here..."
            ></textarea>
            <button
              onClick={handleReplySubmit}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 mb-2"
            >
              Send Reply
            </button>
            <button
              onClick={() => handleDeleteMessage(selectedMessage.id)}
              className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Delete Message
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageManagement;
