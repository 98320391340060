/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { SocialIcon } from 'react-social-icons';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-800 p-6 text-white mt-10 animate-fade-in-up">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h3 className="text-xl font-bold mb-2">The Guys Real Estate</h3>
          <p className="text-sm">&copy; {currentYear} The Guys Real Estate. All rights reserved.</p>
        </div>
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h4 className="text-lg font-bold mb-2">Quick Links</h4>
          <ul>
            <li className="mb-1"><a href="/" className="hover:underline hover:text-blue-400 transition duration-300">Home</a></li>
            <li className="mb-1"><a href="/listings" className="hover:underline hover:text-blue-400 transition duration-300">Listings</a></li>
            <li className="mb-1"><a href="/ContactUs" className="hover:underline hover:text-blue-400 transition duration-300">Contact Us</a></li>
            <li className="mb-1"><a href="/AboutUs" className="hover:underline hover:text-blue-400 transition duration-300">About Us</a></li>
          </ul>
        </div>
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h4 className="text-lg font-bold mb-2">Contact Us</h4>
          <p className="text-sm">Kumasi, Ashanti Region Ghana</p>
          <p className="text-sm mt-2">Email: <a href="mailto:theguysrealestate@gmail.com" className="hover:underline hover:text-blue-400 transition duration-300">theguysrealestate@gmail.com</a></p>
          <p className="text-sm">Phone: <a href="tel:+233508119762" className="hover:underline hover:text-blue-400 transition duration-300">+233 508119762</a> and <a href="tel:+233550742307" className="hover:underline hover:text-blue-400 transition duration-300">+233 550742307</a></p>
        </div>
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h4 className="text-lg font-bold mb-2">Follow Us</h4>
          <div className="flex space-x-4">
            <SocialIcon url="https://www.facebook.com/profile.php?id=100095507257567" className="transition duration-300" />
            <SocialIcon url="https://www.instagram.com/theguysrealestate/?igsh=ZHRicTk4dmQ3a3Bn" className="transition duration-300" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
