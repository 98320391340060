// src/utils/activityLogger.js
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { auth } from '../firebase';

const logActivity = async (userId, category, message) => {
  const user = auth.currentUser;
  const userNameOrEmail = user ? user.displayName || user.email : 'Unknown User';

  try {
    await addDoc(collection(db, 'activityLogs'), {
      userId,
      user: userNameOrEmail,
      category,
      message,
      timestamp: serverTimestamp(),
    });
  } catch (error) {
    console.error('Error logging activity:', error);
  }
};

export default logActivity;
