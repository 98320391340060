import React from 'react';

const Sidebar = ({ setSelectedSection }) => (
  <div className="bg-gray-800 text-white w-64 space-y-6 py-7 px-2">
    <button onClick={() => setSelectedSection('dashboard')} className="text-white text-3xl font-semibold block px-4 py-2">
      Admin Panel
    </button>
    <nav>
      <button onClick={() => setSelectedSection('dashboard')} className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 w-full text-left">
        Dashboard
      </button>
      <button onClick={() => setSelectedSection('properties')} className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 w-full text-left">
        Properties
      </button>
      <button onClick={() => setSelectedSection('activities')} className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 w-full text-left">
        Activities
      </button>
      <button onClick={() => setSelectedSection('messages')} className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 w-full text-left">
        Messages
      </button>
      <button onClick={() => setSelectedSection('edit-property')} className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 w-full text-left">
        Edit Property
      </button>
    </nav>
  </div>
);

export default Sidebar;
