import React from 'react';
import pic1 from '../assets/pic1.jpg'; // Ensure the file extensions are correct
import pic2 from '../assets/pic2.jpg';
import pic3 from '../assets/pic3.jpg';
import pic4 from '../assets/pic4.jpg';
import pic5 from '../assets/pic5.jpg';
import pic6 from '../assets/pic6.jpg';
import pic7 from '../assets/pic7.jpg';

const AboutUs = () => {
   const teamMembers = [
    {
      name: 'Kassim Issaka',
      position: 'CEO & Founder',
      description: 'Kassim Issaka has a vision for transforming the real estate industry and has been leading the company with his innovative strategies and passion for excellence.',
      image: pic1,
    },
    {
      name: 'Samuel Donkor',
      position: 'Valuation and Estate Manager (Co-Founder)',
      description: 'Samuel Donkor specializes in property valuation and estate management, ensuring that all properties are accurately assessed and well-maintained.',
      image: pic2,
    },
    {
      name: 'Oteng Fordjour Derrick',
      position: 'Property Manager (Co-Founder)',
      description: 'Oteng Fordjour Derrick is dedicated to managing our properties efficiently, ensuring that all tenants and clients receive top-notch service.',
      image: pic3,
    },
    {
      name: 'Stephen',
      position: 'Real Estate Broker/Investor',
      description: 'Stephen is a seasoned real estate broker and investor, providing clients with expert advice and investment opportunities in the real estate market.',
      image: pic4,
    },
    {
      name: 'Amofa Prince',
      position: 'Chief Architect',
      description: 'Amofa Prince leads our architectural team, designing innovative and sustainable buildings that meet the highest standards of functionality and aesthetics.',
      image: pic5,
    },
    {
      name: 'Samuel Owusu',
      position: 'Lead Software Engineer',
      description: 'Samuel Owusu is at the forefront of our technological initiatives, developing software solutions that enhance our real estate services and client experiences.',
      image: pic6,
    },
    {
      name: 'Adams Smith',
      position: 'Estate Brokerage And Legal Services',
      description: 'Adams Smith is a seasoned professional in estate brokerage and legal services, ensuring seamless transactions and legal compliance for our clients.',
      image: pic7,
    },
  ];

  const testimonials = [
    {
      name: 'Anonymous',
      text: '"The Guys Real Estate helped me find my dream home in no time. Their service was exceptional, and they made the whole process smooth and stress-free."',
      rating: 5,
    },
    {
      name: 'Anonymous',
      text: '"Highly recommend The Guys Real Estate for anyone looking to buy or sell property. Their professionalism and expertise are unmatched."',
      rating: 5,
    },
    {
      name: 'Anonymous',
      text: '"Excellent service from start to finish. The team was always available to answer my questions and provide valuable advice."',
      rating: 4,
    },
    {
      name: 'Anonymous',
      text: '"Great experience with The Guys Real Estate. They made the process of selling my property easy and hassle-free."',
      rating: 4,
    },
    {
      name: 'Anonymous',
      text: '"The best real estate service I have ever used. Friendly staff and outstanding results."',
      rating: 5,
    },
  ];

  const renderStars = (rating) => {
    return (
      <div className="flex justify-center">
        {Array(rating)
          .fill()
          .map((_, i) => (
            <span key={i} className="text-yellow-500">★</span>
          ))}
        {Array(5 - rating)
          .fill()
          .map((_, i) => (
            <span key={i} className="text-gray-400">★</span>
          ))}
      </div>
    );
  };

  return (
    <div className="container mx-auto py-16 px-4 lg:px-20">
      <h1 className="text-4xl font-bold mb-12 text-center text-white animate-fade-in">About Us</h1>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-white animate-slide-in-left">Our Story</h2>
        <p className="text-gray-300 mb-6">
          Welcome to <strong>The Guys Real Estate</strong>, the premier real estate firm in the Ashanti Region, Kumasi. With a reputation for excellence and a comprehensive suite of services, we are dedicated to meeting all your real estate needs with the highest standards of professionalism and integrity.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-white animate-slide-in-right">Our Services</h2>
        <ul className="list-disc list-inside text-gray-300 space-y-2">
          <li><strong>Land Sales</strong>: Our extensive portfolio of prime land offerings ensures that we can find the perfect piece of land to suit your personal or business needs. We provide expert guidance throughout the purchasing process to ensure a seamless transaction.</li>
          <li><strong>Property Rental & Sales</strong>: Whether you're looking to rent or buy, our team of experienced professionals is here to help you find the ideal property. We offer a diverse selection of residential and commercial properties, tailored to meet your unique requirements.</li>
          <li><strong>Land Documentation</strong>: Navigating land documentation can be complex, but our expertise simplifies the process. We handle all aspects of documentation, ensuring your land transactions are secure and legally sound.</li>
          <li><strong>Property Valuation</strong>: Our certified valuers provide accurate and reliable property valuations. Whether for sale, purchase, insurance, or taxation, you can count on us for precise and comprehensive valuation reports.</li>
          <li><strong>Property Management</strong>: We offer full-service property management to maximize the value of your investments. From tenant selection to maintenance, we handle all aspects to ensure your property is well-managed and profitable.</li>
          <li><strong>Architectural Designs</strong>: Our innovative architectural team designs aesthetically pleasing and functional spaces. We work closely with you to bring your vision to life, ensuring every detail aligns with your needs and preferences.</li>
          <li><strong>Building Construction</strong>: From initial concept to final construction, we provide end-to-end building solutions. Our commitment to quality craftsmanship and adherence to timelines ensures your project is completed to the highest standards.</li>
          <li><strong>Real Estate Legal Advice</strong>: Our legal experts specialize in real estate law, offering comprehensive advice and support. We help you navigate the legal complexities of property transactions, safeguarding your interests every step of the way.</li>
          <li><strong>Building Estimates</strong>: We provide detailed building estimates to help you plan and budget your construction projects effectively. Our accurate assessments ensure there are no surprises, keeping your project on track and within budget.</li>
          <li><strong>Property Marketing</strong>: Our strategic marketing services are designed to highlight your property’s best features. Through targeted campaigns and innovative approaches, we ensure your property receives maximum visibility and attracts the right buyers or tenants.</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-white animate-slide-in-right">Our Team</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {teamMembers.map((member, index) => (
            <div key={index} className="text-center bg-gray-800 p-6 rounded-lg shadow-lg animate-fade-in">
              <img src={member.image} alt={member.name} className="w-32 h-32 rounded-full mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-white">{member.name}</h3>
              <p className="text-gray-300">{member.position}</p>
              <p className="text-gray-400 mt-2">{member.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-white animate-slide-in-left">Our Commitment</h2>
        <p className="text-gray-300 mb-6">
          At <strong>The Guys Real Estate</strong>, we pride ourselves on our customer-centric approach. Our team is dedicated to providing personalized service, ensuring that every client receives the attention and expertise they deserve. We are committed to transparency, reliability, and excellence in all our dealings.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-white animate-slide-in-right">Why Choose Us?</h2>
        <ul className="list-disc list-inside text-gray-300 space-y-2">
          <li><strong>Experience and Expertise</strong>: With years of experience in the real estate industry, our knowledgeable team is equipped to handle all your real estate needs.</li>
          <li><strong>Comprehensive Services</strong>: Our wide range of services ensures that we can assist you at every stage of your real estate journey.</li>
          <li><strong>Local Knowledge</strong>: Being based in Kumasi, we have deep insights into the local market and community, giving us a distinct advantage in serving your needs.</li>
          <li><strong>Client Satisfaction</strong>: Our top priority is your satisfaction. We strive to build long-term relationships based on trust and exceptional service.</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-white animate-slide-in-left">Testimonials</h2>
        <div className="space-y-4">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-gray-800 p-6 rounded shadow-lg animate-fade-in">
              <p className="text-gray-300">{testimonial.text}</p>
              {renderStars(testimonial.rating)}
              <p className="text-gray-400 mt-2">- {testimonial.name}</p>
            </div>
          ))}
        </div>
      </section>

      <section>
        <h2 className="text-3xl font-semibold mb-6 text-white animate-slide-in-right">Contact Us</h2>
        <p className="text-gray-300">
          If you have any questions or would like to get in touch with us, please feel free to contact us at:
        </p>
        <p className="text-gray-300 mt-4">Email: <a href="mailto:theguysrealestate@gmail.com" className="hover:underline text-blue-500">theguysrealestate@gmail.com</a></p>
        <p className="text-gray-300 mt-2">Phone: <a href="tel:+233508119762" className="hover:underline text-blue-500">+233 508119762</a> and <a href="tel:+233550742307" className="hover:underline text-blue-500">+233 550742307</a></p>
        <p className="text-gray-300 mt-2">Address: Kumasi, Ashanti Region Ghana</p>
      </section>
    </div>
  );
};

export default AboutUs;
