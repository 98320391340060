import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.jpg';
import { AuthContext } from '../../context/AuthContext';

const Hero = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleExploreListings = () => {
    if (currentUser) {
      navigate('/listings');
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="relative bg-cover bg-center h-[60vh] flex items-center justify-center" style={{ backgroundImage: `url(${logo})` }}>
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>
      <div className="relative z-10 text-center p-6 animate-fade-in-up space-y-4">
        <h1 className="text-3xl md:text-5xl font-bold text-white mb-4 animate-slide-in-down">Welcome to The Guys Real Estate</h1>
        <p className="text-lg md:text-2xl text-white mb-6 animate-slide-in-up">Find your perfect property with us</p>
        <button
          onClick={handleExploreListings}
          className="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded animate-bounce"
        >
          Explore Listings
        </button>
        <div className="text-white mt-6 space-y-2 animate-slide-in-up">
          <p className="text-sm md:text-lg">Buy, sell, or rent properties with ease.</p>
          <p className="text-sm md:text-lg">Your dream home is just a click away.</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
