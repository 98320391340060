/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react';
import Sidebar from '../components/AdminPanel/Sidebar';
import PropertyManagement from '../components/AdminPanel/PropertyManagement'; // Ensure this path is correct
import ActivityLog from '../components/AdminPanel/ActivityLog'; // Ensure this path is correct
import MessageManagement from '../components/AdminPanel/MessageManagement'; // Ensure this path is correct
import EditProperty from '../components/AdminPanel/EditProperty'; // Ensure this path is correct


const AdminPanel = () => {
  const [selectedSection, setSelectedSection] = useState('dashboard');

  const renderSection = () => {
    switch (selectedSection) {
      case 'properties':
        return <PropertyManagement />;
      case 'activities':
        return <ActivityLog />;
      case 'messages':
        return <MessageManagement />;
      case 'edit-property':
        return <EditProperty />;
      default:
        return <Dashboard setSelectedSection={setSelectedSection} />;
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar setSelectedSection={setSelectedSection} />
      <div className="flex-grow p-6">
        {renderSection()}
      </div>
    </div>
  );
};

const Dashboard = ({ setSelectedSection }) => {
  return (
    <div>
      <h1 className="text-4xl font-bold mb-6">Admin Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md cursor-pointer" onClick={() => setSelectedSection('properties')}>
          <h2 className="text-xl font-semibold mb-4">Property Management</h2>
          <p className="text-gray-700">Manage all properties listed on the platform.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md cursor-pointer" onClick={() => setSelectedSection('activities')}>
          <h2 className="text-xl font-semibold mb-4">Activity Log</h2>
          <p className="text-gray-700">View recent activities of users and admins.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md cursor-pointer" onClick={() => setSelectedSection('messages')}>
          <h2 className="text-xl font-semibold mb-4">Message Management</h2>
          <p className="text-gray-700">View and respond to messages from clients.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md cursor-pointer" onClick={() => setSelectedSection('edit-property')}>
          <h2 className="text-xl font-semibold mb-4">Edit Property</h2>
          <p className="text-gray-700">Edit or delete properties listed on the platform.</p>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
