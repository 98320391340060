/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, query, orderBy, limit, startAfter, getDocs, where, deleteDoc, doc } from 'firebase/firestore';
import { CSVLink } from 'react-csv';
import { FaTrash } from 'react-icons/fa';

const ActivityLog = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('');
  const [detailedLog, setDetailedLog] = useState(null);

  useEffect(() => {
    fetchLogs();
  }, [filter, searchTerm]);

  const fetchLogs = async () => {
    setLoading(true);
    try {
      let q = query(
        collection(db, 'activityLogs'),
        orderBy('timestamp', 'desc'),
        limit(10)
      );

      if (filter) {
        q = query(q, where('category', '==', filter));
      }

      if (searchTerm) {
        q = query(q, where('message', '>=', searchTerm), where('message', '<=', searchTerm + '\uf8ff'));
      }

      const querySnapshot = await getDocs(q);
      const logsData = [];
      querySnapshot.forEach((doc) => {
        logsData.push({ ...doc.data(), id: doc.id });
      });
      setLogs(logsData);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
    setLoading(false);
  };

  const fetchMoreLogs = async () => {
    if (!lastVisible) return;

    setLoading(true);
    try {
      let q = query(
        collection(db, 'activityLogs'),
        orderBy('timestamp', 'desc'),
        startAfter(lastVisible),
        limit(10)
      );

      if (filter) {
        q = query(q, where('category', '==', filter));
      }

      if (searchTerm) {
        q = query(q, where('message', '>=', searchTerm), where('message', '<=', searchTerm + '\uf8ff'));
      }

      const querySnapshot = await getDocs(q);
      const logsData = [];
      querySnapshot.forEach((doc) => {
        logsData.push({ ...doc.data(), id: doc.id });
      });
      setLogs((prevLogs) => [...prevLogs, ...logsData]);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } catch (error) {
      console.error('Error fetching more logs:', error);
    }
    setLoading(false);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // The fetchLogs useEffect will handle the search automatically due to dependency on searchTerm
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  const showDetailedLog = (log) => {
    setDetailedLog(log);
  };

  const handleCloseDetailedLog = () => {
    setDetailedLog(null);
  };

  const handleDeleteLog = async (logId) => {
    try {
      await deleteDoc(doc(db, 'activityLogs', logId));
      setLogs(logs.filter(log => log.id !== logId));
    } catch (error) {
      console.error('Error deleting log:', error);
    }
  };

  const headers = [
    { label: 'Message', key: 'message' },
    { label: 'Timestamp', key: 'timestamp' },
    { label: 'Details', key: 'details' },
    { label: 'User', key: 'user' },
  ];

  return (
    <div className="container mx-auto py-8 px-4 lg:px-20">
      <h2 className="text-3xl font-bold mb-6 text-center text-black">Activity Log</h2>
      <form onSubmit={handleSearch} className="mb-4">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
        />
      </form>
      <div className="mb-4">
        <select
          value={filter}
          onChange={handleFilter}
          className="w-full p-3 border border-gray-600 rounded bg-gray-900 text-gray-300 focus:outline-none focus:border-blue-500"
        >
          <option value="">All Activities</option>
          <option value="user">User Activities</option>
          <option value="property">Property Management</option>
          <option value="booking">Bookings</option>
          <option value="admin">Administrative Actions</option>
          <option value="system">System Notifications</option>
        </select>
      </div>
      <CSVLink data={logs} headers={headers} filename="activity_logs.csv" className="mb-4 inline-block bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
        Export as CSV
      </CSVLink>
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-6">
        {loading ? (
          <p className="text-center text-gray-300">Loading...</p>
        ) : (
          logs.map((log) => (
            <div key={log.id} className="bg-gray-900 p-4 rounded-lg shadow-md flex justify-between items-center">
              <div onClick={() => showDetailedLog(log)} className="cursor-pointer">
                <p className="text-gray-300">{log.message}</p>
                <p className="text-gray-500 text-sm">{new Date(log.timestamp?.toDate()).toLocaleString()}</p>
                <p className="text-gray-500 text-sm">{log.user}</p>
              </div>
              <button
                onClick={() => handleDeleteLog(log.id)}
                className="ml-4 text-red-600 hover:text-red-800"
              >
                <FaTrash />
              </button>
            </div>
          ))
        )}
      </div>
      {lastVisible && (
        <button
          onClick={fetchMoreLogs}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded transition duration-300 mt-6"
          disabled={loading}
        >
          {loading ? 'Loading More...' : 'Load More'}
        </button>
      )}
      {detailedLog && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
          <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4">Activity Details</h3>
            <p className="mb-2"><strong>Message:</strong> {detailedLog.message}</p>
            <p className="mb-2"><strong>Timestamp:</strong> {new Date(detailedLog.timestamp?.toDate()).toLocaleString()}</p>
            <p className="mb-2"><strong>Details:</strong> {detailedLog.details}</p>
            <p className="mb-2"><strong>User:</strong> {detailedLog.user}</p>
            <button
              onClick={handleCloseDetailedLog}
              className="mt-4 bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityLog;
