import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBm08YMLO_PWxpXSW8_cteBo9UoXj4Vhpo",
  authDomain: "realestate-7617a.firebaseapp.com",
  projectId: "realestate-7617a",
  storageBucket: "realestate-7617a.appspot.com",
  messagingSenderId: "233549655540",
  appId: "1:233549655540:web:cb2d814b68f011db729da1",
  measurementId: "G-TTTR62FJY8"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
