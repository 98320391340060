import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.jpg';
import { AuthContext } from '../../context/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';

const Navbar = () => {
  const { currentUser } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && currentUser.email === 'sam@realestate.com') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-gray-800 p-4 text-white shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          {isAdmin ? (
            <Link to="/admin-panel" className="flex items-center">
              <img src={logo} alt="The Guys Real Estate" className="w-12 h-12 mr-2 rounded-full" />
              <span className="text-xl font-bold hover:text-blue-400 transition duration-300">The Guys Real Estate</span>
            </Link>
          ) : (
            <Link to="/" className="flex items-center">
              <img src={logo} alt="The Guys Real Estate" className="w-12 h-12 mr-2 rounded-full" />
              <span className="text-xl font-bold hover:text-blue-400 transition duration-300">The Guys Real Estate</span>
            </Link>
          )}
        </div>
        <div className="hidden md:flex space-x-4 items-center">
          {currentUser && (
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-300">{currentUser.email}</span>
            </div>
          )}
          {currentUser ? (
            isAdmin ? (
              <>
                <button onClick={handleLogout} className="hover:underline hover:text-blue-400 transition duration-300">Logout</button>
              </>
            ) : (
              <>
                <Link to="/listings" className="hover:underline hover:text-blue-400 transition duration-300">Listings</Link>
                <Link to="/profile" className="hover:underline hover:text-blue-400 transition duration-300">Profile</Link>
                <Link to="/ContactUs" className="hover:underline hover:text-blue-400 transition duration-300">Contact Us</Link>
                <button onClick={handleLogout} className="hover:underline hover:text-blue-400 transition duration-300">Logout</button>
              </>
            )
          ) : (
            <>
              <Link to="/login" className="hover:underline hover:text-blue-400 transition duration-300">Sign In</Link>
              <Link to="/register" className="hover:underline hover:text-blue-400 transition duration-300">Register</Link>
              <Link to="/admin-login" className="hover:underline hover:text-blue-400 transition duration-300">Admin</Link>
            </>
          )}
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden bg-gray-800">
          {currentUser && (
            <div className="flex items-center space-x-2 px-4 py-2">
              <span className="text-sm text-gray-300">{currentUser.email}</span>
            </div>
          )}
          {currentUser ? (
            isAdmin ? (
              <>
                <button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:underline hover:text-blue-400 transition duration-300">Logout</button>
              </>
            ) : (
              <>
                <Link to="/listings" className="block px-4 py-2 hover:underline hover:text-blue-400 transition duration-300">Listings</Link>
                <Link to="/profile" className="block px-4 py-2 hover:underline hover:text-blue-400 transition duration-300">Profile</Link>
                <Link to="/ContactUs" className="block px-4 py-2 hover:underline hover:text-blue-400 transition duration-300">Contact Us</Link>
                <button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:underline hover:text-blue-400 transition duration-300">Logout</button>
              </>
            )
          ) : (
            <>
              <Link to="/login" className="block px-4 py-2 hover:underline hover:text-blue-400 transition duration-300">Sign In</Link>
              <Link to="/register" className="block px-4 py-2 hover:underline hover:text-blue-400 transition duration-300">Register</Link>
              <Link to="/admin-login" className="block px-4 py-2 hover:underline hover:text-blue-400 transition duration-300">Admin</Link>
            </>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
